import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';

const CardDash = ({ title, value, icon }) => {
  return (
    <Grid item xs={3}>
      <Paper elevation={2} sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="subtitle1" sx={{ fontSize: 14 }}>
            {title}
          </Typography>
          {icon && (
            <Box component="img" src={icon} alt="icon" sx={{ width: 36, height: 36 }} />
          )}
        </Box>
        <Typography variant="h1" sx={{ fontSize: 26 }}>
          {value}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default CardDash;
