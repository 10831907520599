import React, { useEffect } from 'react';
import ClientRoutes from './routes/ClientRoutes';
import AdminRoutes from './routes/AdminRoutes';
import AuthRoutes from './routes/AuthRoutes';
import { Route, Routes, useNavigate } from 'react-router-dom'; // useNavigate added
import theme from './theme/theme';
import { ThemeProvider } from '@mui/material/styles';
import { Provider, useDispatch, useSelector } from 'react-redux'; // useDispatch added
import store from './store/store';

const App = () => {
  const dispatch = useDispatch(); // To dispatch actions to Redux store
  const navigate = useNavigate(); // To navigate programmatically

  // Access the token and role from Redux
  const token = useSelector((state) => state.user.token); // Assuming token is stored in state.auth.token
  const userRole = useSelector((state) => state.user.role); // Assuming user role is stored in state.user.role

  

  useEffect(() => {
    if (!token) {
      navigate('/')
    } 
  }, [token, navigate]); // Listen for changes in the token and navigate

  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route path="/" element={<AuthRoutes />} />
        {token && userRole === 'ADMIN' ? (
          <Route path="/*" element={<AdminRoutes />} />
        ) : (
          <Route path="/*" element={<ClientRoutes />} />
        )}
      </Routes>
    </ThemeProvider>
  );
};

// Wrap your App component with Provider so Redux store is available
const WrappedApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

export default WrappedApp;
