import axios from 'axios';

// Create an Axios instance
const api = axios.create({
    baseURL: 'https://intervenantapi.omnidoc.ma', // Update with your Laravel backend URL
    headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache', // Make sure this is set

    },
});

// Registration function
export const registerUser = async (userData) => {
    try {
        const response = await api.post('/register', userData);
        return response.data; // Return the response data
    } catch (error) {
        // Handle error
        if (error.response) {
            // The request was made, and the server responded with a status code
            throw new Error(error.response.data); // You can customize this message
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request
            throw new Error('Error in request setup');
        }
    }
};

// Login function
export const loginUser = async (credentials) => {
    try {
        const response = await api.post('api/login', credentials);
        return response.data; // Returns token and user data
    } catch (error) {
        // Handle error
        if (error.response) {
            // The request was made, and the server responded with a status code
            throw new Error(error.response.data.message || 'Login failed'); // Custom error message
        } else if (error.request) {
            // The request was made but no response was received
            throw new Error('No response from server');
        } else {
            // Something happened in setting up the request
            throw new Error('Error in request setup');
        }
    }
};

// Function to store the FCM token in the backend
export const storeFcmToken = async (fcmToken) => {
    try {
        const response = await api.post('api/fcm-tokens', fcmToken);
        console.log(response,'response')
        return response.data;
    } catch (error) {
      console.error('Error storing FCM token:', error);
      throw error;
    }
  };

  // Function to delete the FCM token in the backend
export const deleteFcmToken = async (userId, fcmToken) => {
    try {
        const response = await api.delete('api/fcm-token', {
            data: {
                user_id: userId, // Pass user_id
                fcm_token: fcmToken // Pass fcm_token
            }
        });
        return response.data; // Return the response data
    } catch (error) {
        console.error('Error deleting FCM token:', error);
        throw error;
    }
};