// userSlice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: null,
  role: null,
  isLoggedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      state.id = action.payload.id;
      state.role = action.payload.role;
      state.name = action.payload.name;
      state.token=action.payload.token;
      state.isLoggedIn = true;
    },
    logout: (state) => {
      state.id = null;
      state.role = null;
      state.isLoggedIn = false;
    },
  },
});

export const { login, logout } = userSlice.actions;
export default userSlice.reducer;
