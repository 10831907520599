import axios from 'axios';

// Base API instance (you can reuse your auth config if needed)
const api = axios.create({
    baseURL: 'https://intervenantapi.omnidoc.ma/api',
    headers: {
        'Content-Type': 'application/json',
        // Add authentication headers here if needed (Bearer token, etc.)
    },
});

export const createIntervention = async (interventionData) => {
    try {
        const response = await api.post('/interventions', interventionData);
        console.log(response.data)
        return response.data;
    } catch (error) {
        console.error('Error creating intervention', error);
        throw error;
    }
};

// Function to get all interventions with user data
export const getAllInterventionsWithUsers = async () => {
    try {
        const response = await api.get('/interventions-with-users');
        console.log(response.data);
        return response.data;
    } catch (error) {
        console.error('Error fetching interventions', error);
        throw error;
    }
};

// New function to get client-specific interventions
export const getClientInterventions = async (token) => {
    try {
        const response = await api.get('/my-interventions', {
            headers: {
                'Authorization': `Bearer ${token}`, // Using token from Redux
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching client interventions', error);
        throw error;
    }
};

// Function to update the status of an intervention
export const updateInterventionStatus = async (interventionId, newStatusId) => {
    try {
        const response = await api.put(`/interventions/${interventionId}/status`, {
            status_id: newStatusId, // Assuming 'CurrentStatusID' is the column name for the status in the database
        });

        console.log('Intervention status updated:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error updating intervention status', error);
        throw error;
    }
};