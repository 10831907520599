import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#29BACD', 
    },
    secondary: {
      main: '#455879', 
    },
    three: {
      main: '#8590A2', 
    },
  },

  typography: {
    h1: {
      fontSize: '18px',
      fontWeight: 700,
      color: '#455879',
      letterSpacing: '0.5px',
      marginBottom: '16px',
      fontFamily: 'Arial, sans-serif', 

    },
    h2: {
      fontSize: '14px',
      fontWeight: 600,
      color: '#455879',
      marginBottom: '12px',
      fontFamily: 'Arial, sans-serif', 
    },
    h3: {
      fontSize: '12px',
      fontWeight: 600,
      color: '#455879',
      marginBottom: '12px',
      fontFamily: 'Arial, sans-serif', 
    },
    subtitle1: {
      fontSize: '12px',
      fontWeight: 400,
      color: '#8590A2',
      marginBottom: '8px',
      fontFamily: 'Arial, sans-serif', 
    },
    textbody1: {
      fontSize: '16px',
      fontWeight: 300,
      color: '#8590A2',
      marginBottom: '8px',
      fontFamily: 'Arial, sans-serif', 
    },
    
  },
});

export default theme;
