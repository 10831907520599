// src/pages/Auth/SignIn.jsx
import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  Avatar,
  Paper,
  styled,
  Box,
  CssBaseline,
  Checkbox, FormControlLabel,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { SvgIcon } from '@mui/material';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import backgroundImage from '../../assets/images/omnidoc_bg.png';
import ResponsiveAppBar from '../../components/AppBar';
import { useNavigate } from 'react-router-dom';
import SendIcon from '@mui/icons-material/Send'; 
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import MarocFlag from '../../assets/images/maroc_flag.png';
import { createIntervention } from '../../api/interventionApi';
import {useSelector} from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Description } from '@mui/icons-material';

const PaperStyled = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#ffffff', 
}));


const AvatarStyled = styled(Avatar)(({ theme }) => ({
  margin: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,
}));

const FormStyled = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const AddMission = () => {
  const userId = useSelector((state) => state.user.id);
  const [Error,setError]=useState({
    InterventionType:'',
    
    StartAddress1:'',
    DestinationAddress1:'',
    PreferredDate:'',
    OrderNumber:'',
    PhoneNumber:''

  });
  const [priority, setPriority] = useState('');
  const [isRetourChecked, setIsRetourChecked] = useState(false);
  const [notification, setNotification] = useState(''); // State to handle notification message
  const location = useLocation();
  const InterventionData = location.state;
  const [interventionData, setInterventionData] = useState({
    ClientID:userId,
    InterventionType:InterventionData? InterventionData.InterventionType:'',
    Priority:  InterventionData? InterventionData.Priority:priority ,
     Description: InterventionData? InterventionData.Description:'',
    IsRoundTrip:  InterventionData? InterventionData.IsRoundTrip:isRetourChecked ,
     StartAddress1:  InterventionData? InterventionData.StartAddress1:'',
     DestinationAddress1: InterventionData? InterventionData.DestinationAddress1:'',
     StartAddress2: InterventionData? InterventionData.StartAddress2:'',
     DestinationAddress2: InterventionData? InterventionData.DestinationAddress2:'',
    PatientName: InterventionData? InterventionData.PatientName:'',
     OrderNumber: InterventionData? InterventionData.OrderNumber:'',
     PreferredDate:InterventionData? InterventionData.PreferredDate:'',
    CurrentStatusID: 1,
    PhoneNumber:InterventionData? InterventionData.PhoneNumber:'',
    emailAdress:InterventionData? InterventionData.emailAdress:''
  });

  
  const handlePriorityChange = (event) => {
    const newPriority = event.target.value;
    setPriority(newPriority);
      setInterventionData((prevData) => ({
        ...prevData,
        Priority: newPriority, // Update the priority field
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInterventionData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  
   // Validation function now returns true if validation is successful, false otherwise
const handleValidation = () => {
  let errors = {};
  let isValid = true; // Flag to track validation status

  if (interventionData.InterventionType === '') {
    errors.InterventionType = 'Le type est obligatoire';
    isValid = false; // Set validation to false if any field is invalid
  }

  if (interventionData.StartAddress1 === '') {
    errors.StartAddress1 = "L'adresse départ est obligatoire";
    isValid = false;
  }

  if (interventionData.DestinationAddress1 === '') {
    errors.DestinationAddress1 = "L'adresse d'arrivée est obligatoire";
    isValid = false;
  }

  if (interventionData.OrderNumber === '') {
    errors.OrderNumber = 'Le Bon de Commande est obligatoire';
    isValid = false;
  }

  if (interventionData.PreferredDate === '') {
    errors.PreferredDate = 'La Date préférée est obligatoire';
    isValid = false;
  }

  if (interventionData.PhoneNumber === '') {
    errors.PhoneNumber = 'Numéro téléphone est obligatoire';
    isValid = false;
  }

  // Update error state
  setError(errors);

  return isValid; // Return the overall validation status
};

// Submit function
const handleSubmit = (e) => {
  e.preventDefault();

  // Check if the form is valid before proceeding with the API call
  const isValid = handleValidation();
  if (isValid) {
    // Simulate API call and handle success/failure
    createIntervention(interventionData)
      .then(() => {
        // On success
        setNotification({
          message: 'Demande intervention envoyée avec succès',
          type: 'success',
        });
      })
      .catch(() => {
        // On failure
        setNotification({
          message: 'Échec de l\'envoi de la demande d\'intervention',
          type: 'error',
        });
      });

    // Clear the notification after 3 seconds (optional)
    setTimeout(() => {
      setNotification({ message: '', type: '' });
    }, 2000);
  }
};

  
  const handleRetourChange = (event) => {
    setIsRetourChecked(event.target.checked);
  };


  const navigate = useNavigate();

  const [selectedDate, setSelectedDate] = React.useState(null);
 
  const toMyRequests = (e) => {
    e.preventDefault();
    navigate('/client/my-requests');
  };
  const toPreview = (e) => {
    e.preventDefault();
    navigate('/client/preview', { state: interventionData });
  };

  return (
    <>
    <ResponsiveAppBar authenticated={true}/>
    {/* Display the notification at the top */}
    {notification.message && (
        <div
          className={`notification ${notification.type === 'success' ? 'success' : 'error'}`}
          style={{
            backgroundColor: notification.type === 'success' ? 'green' : 'red',
            color: 'white',
            padding: '10px',
            position: 'fixed',
            top: 0,
            width: '100%',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          {notification.message}
        </div>
      )}
      <CssBaseline />
      <Container
       component="main"
       maxWidth={false} 
       sx={{
         minHeight: '100vh',
         width: '100vw', 
         display: 'flex',
         flexDirection: 'column',
         alignItems: 'center',
         justifyContent: 'center',
         backgroundImage: `url(${backgroundImage})`, 
         backgroundSize: 'cover', 
         backgroundPosition: 'center',
         backgroundRepeat: 'no-repeat',
         overflow: 'hidden', 
         padding: 0,
         margin: 0, 
        }}
      >
        <PaperStyled elevation={3} 
        sx={{
          width:"70%",
          display: "flex", 
          flexDirection:'row-reverse',
          alignItems: "center", 
          justifyContent: "space-between",
          padding:8,
          marginY:8,
        }}
        >
        <PaperStyled elevation={0} sx={{
          width:"100%",
          height:"100%",
          alignItems: "start", 
          justifyContent: "start",
          background:"#F8FAFC",
          padding:2,
          margin:0,
        }}>

            <Typography variant="h1"sx={{
              fontSize:36,
            }} >
            Demande d'intervention médicale
            </Typography>

            <Typography variant="h2" sx={{
              fontWeight:200
            }}>
            Merci de remplir ce formulaire pour demander une intervention médicale. Nous vous contacterons dès que possible pour confirmer la date et l'heure.
            </Typography>

           
            <Container maxWidth="lg"  sx={{ marginTop: 4 }}>
              <Typography variant="h2" sx={{ marginBottom: 1 }}>
              Type d'intervention *
              </Typography>
              <Typography style={{color:'red',fontSize:12}}>{Error.InterventionType}</Typography>

              <Select
                label="type d'intervention"
                 
                placeholder='Sélectionnez le type'
                sx={{
                  background: "#FFFFFF",
                   width:"100%"
                }}
                value={interventionData.InterventionType}
                onChange={(e) => handleInputChange(e)} // Make sure to pass the event
                name='InterventionType'
              >
                <MenuItem value={"Intervention d'urgence"}>Intervention d'urgence</MenuItem>
                <MenuItem value={'Consultation'}>Consultation</MenuItem>
                <MenuItem value={'Examen clinique'}>Examen clinique </MenuItem>
                <MenuItem value={'Prise de sang'}>Prise de sang</MenuItem>
                {/* Add more MenuItem components as needed */}
              </Select>
            </Container>

              

            <Container maxWidth="lg" sx={{ marginTop: 4 }}>
            <Typography variant="h2" sx={{ marginBottom: 1 }}>
              Description d'intervention 
              </Typography>
            <TextField
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              placeholder="Décrivez les besoins de l'intervention"
              sx={{
                background: "#FFFFFF",
                width:"100%"
              }}
              name='Description'
              value={interventionData.Description}
              onChange={(e) => handleInputChange(e)} // Make sure to pass the event


            /> 
            </Container>

            <Container
        maxWidth="lg"
        sx={{
          marginTop: 4,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'start'
        }}
      >
        <Checkbox checked={isRetourChecked} onChange={handleRetourChange} />
        <Typography variant="h1" sx={{ margin: 0 }}>
          Aller-retour
        </Typography>
      </Container>

      {isRetourChecked && (
        <>
           <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{
              width:"100%",
            }}>
              <Typography variant="h1" sx={{ marginBottom: 1 }}>
              Adresse de départ 1
              </Typography>
              <TextField 
                variant="outlined" 
                fullWidth 
                placeholder="Adresse complète de départ" 
                InputProps={{
                  sx: {
                    background: "#FFFFFF"
                  }
                }}
                name='StartAddress1'
                value={interventionData.StartAddress1}
                onChange={(e) => handleInputChange(e)} // Make sure to pass the event


              />
            </Container>




            <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{
              width:"100%",
            }}>

              <Typography variant="h1" sx={{ marginBottom: 1 }}>
              Adresse d'arrivée 1
              </Typography>
              <TextField 
                variant="outlined" 
                fullWidth 
                placeholder="Adresse complète d'arrivée" 
                InputProps={{
                  sx: {
                    background: "#FFFFFF"
                  }
                }}
                name='DestinationAddress1'
                value={interventionData.DestinationAddress1}
                onChange={(e) => handleInputChange(e)} // Make sure to pass the event


              />
            </Container>
          <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{ width: "100%" }}>
            <Typography variant="h1" sx={{ marginBottom: 1 }}>
              Adresse de départ 2
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Adresse complète de départ"
              InputProps={{
                sx: {
                  background: "#FFFFFF"
                }
              }}
              name='StartAddress2'
              value={interventionData.StartAddress2}
              onChange={(e) => handleInputChange(e)} // Make sure to pass the event

            />
          </Container>

          <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{ width: "100%" }}>
            <Typography variant="h1" sx={{ marginBottom: 1 }}>
              Adresse d'arrivée 2
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              placeholder="Adresse complète d'arrivée"
              InputProps={{
                sx: {
                  background: "#FFFFFF"
                }
              }}
              name='DestinationAddress2'
              value={interventionData.DestinationAddress2}
              onChange={(e) => handleInputChange(e)} // Make sure to pass the event

            />
          </Container>
        </>
      )}
      {
        !isRetourChecked &&(
          <>
           <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{
              width:"100%",
            }}>
              <Typography variant="h1" sx={{ marginBottom: 1 }}>
              Adresse de départ 
              </Typography>
              <Typography style={{color:'red',fontSize:12}}>{Error.StartAddress1}</Typography>
              <TextField 
                variant="outlined" 
                fullWidth 
                placeholder="Adresse complète de départ" 
                InputProps={{
                  sx: {
                    background: "#FFFFFF"
                  }
                }}
                name='StartAddress1'
                value={interventionData.StartAddress1}
                onChange={(e) => handleInputChange(e)} // Make sure to pass the event


              />
            </Container>




            <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{
              width:"100%",
            }}>
              <Typography variant="h1" sx={{ marginBottom: 1 }}>
              Adresse d'arrivée 
              </Typography>
              <Typography style={{color:'red',fontSize:12}}>{Error.DestinationAddress1}</Typography>
              <TextField 
                variant="outlined" 
                fullWidth 
                placeholder="Adresse complète d'arrivée" 
                InputProps={{
                  sx: {
                    background: "#FFFFFF"
                  }
                }}
                name='DestinationAddress1'
                value={interventionData.DestinationAddress1}
                onChange={(e) => handleInputChange(e)} // Make sure to pass the event


              />
            </Container>
          </>
           
        )
      }



            <Container maxWidth="lg">
            <Typography variant="h2" sx={{margin:0, fontWeight:200}} >si vous avez besoin d'une ambulance, allez-y et si vous choisissez cette option</Typography>  
            
            <Typography variant="h1" sx={{marginTop:4}} >Sélectionner</Typography>            
            </Container>

      
            <Container maxWidth="lg">
              <RadioGroup
                aria-label="priority"
                name="priority"
                value={priority}
                onChange={handlePriorityChange}
                sx={{ display: 'flex', flexDirection: 'row' }}
              >
                <FormControlLabel
                  value="urgent"
                  control={<Radio />}
                  label="Urgent"
                />
                <FormControlLabel
                  value="planifie"
                  control={<Radio />}
                  label="Planifié"
                />
              </RadioGroup>

              {priority === 'planifie' && (
                       
                      <Typography variant="h1" sx={{ marginTop: 4 }}>
                      Date préférée *{"\n"}
                      <Typography style={{color:'red',fontSize:12}}>{Error.PreferredDate}</Typography>

                    </Typography>
                    

              )}

              {priority === 'planifie' && (
                <TextField
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  placeholder="Date préférée *"
                  sx={{ background: "#FFFFFF" }}
                  value={interventionData.PreferredDate}
                  name='PreferredDate'
                  onChange={(e) => handleInputChange(e)} // Make sure to pass the event

                />
              )}
            </Container>

            <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{
              width:"100%",
            }}>
              <Typography variant="h1" sx={{ marginBottom: 1 }}>
                Bon de commande
              </Typography>
              <Typography style={{color:'red',fontSize:12}}>{Error.OrderNumber}</Typography>
              <TextField 
                variant="outlined" 
                fullWidth 
                placeholder="Numéro du bon de commande" 
                InputProps={{
                  sx: {
                    background: "#FFFFFF"
                  }
                }}
                name='OrderNumber'
                value={interventionData.OrderNumber}
                onChange={(e) => handleInputChange(e)} // Make sure to pass the event


              />
            </Container>


            <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{
              width:"100%",
            }}>
              <Typography variant="h1" sx={{ marginBottom: 1 }}>
              Nom du patient
              </Typography>
              <TextField 
                variant="outlined" 
                fullWidth 
                placeholder="Nom complet du patient"
                name='PatientName' 
                InputProps={{
                  sx: {
                    background: "#FFFFFF"
                  }
                }}
                
                value={interventionData.PatientName}
                onChange={(e) => handleInputChange(e)} // Make sure to pass the event


              />
            </Container>



           



            <Container maxWidth="lg" sx={{ marginTop: 4 }}>

            <Typography variant="h1" sx={{ marginBottom: 1 }}>
            Numéro de téléphone 
              </Typography>
              
             <Typography style={{color:'red',fontSize:12}}>{Error.PhoneNumber}</Typography>
              <TextField
                    variant="outlined"
                    fullWidth
                    placeholder="Numéro de téléphone"
                    value={interventionData.PhoneNumber}
                    onChange={(e) => handleInputChange(e)} // Make sure to pass the event
                    name='PhoneNumber'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              backgroundImage: `url(${MarocFlag})`, 
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              width: 30, 
                              height: 20,
                              marginRight: 1,
                              borderRadius: 1,
                              backgroundColor: 'white', 
                            }}
                          >
                          </Box>
                          <span style={{ marginLeft: 8 }}>+212</span>

                        </InputAdornment>
                      ),
                    }}
                    sx={{ background: "#FFFFFF" }}
                  />
            </Container>
            
            {/* //the number */}




            <Container maxWidth="lg" sx={{ marginTop: 4 }} style={{
              width:"100%",
            }}>
              <Typography variant="h1" sx={{ marginBottom: 1 }}>
              Adresse e-mail 
              </Typography>
              <TextField 
                variant="outlined" 
                fullWidth 
                placeholder="Adresse e-mail" 
                InputProps={{
                  sx: {
                    background: "#FFFFFF"
                  }
                }}
                value={interventionData.emailAdress}
                onChange={(e) => handleInputChange(e)} // Make sure to pass the event
                name='emailAdress'
              />
            </Container>







            <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'space-between', marginY:4 }}>

            <div style={{ width:'70%'  }}>
        <Button
          variant="outlined"
          color="primary"
          startIcon={<RemoveRedEyeIcon />}
          fullWidth
          sx={{
            paddingX:2,
            paddingX:4
          }}
          onClick={toPreview}
        >

          Prévisualiser pour confirmation
        </Button>
      </div>
      
      {/* Button with Icon */}
      <div style={{ width:'25%' }}>
      <Button
          variant="contained"
          color="primary"
          startIcon={<SendIcon  sx={{ color: '#FFFFFF' }} />}
          fullWidth
          sx={{
            paddingY:1,
            paddingX:8
          }}
          onClick={handleSubmit}
        >
        </Button>
      </div>


            </Container>

                
            





          




   
 

        
      
        </PaperStyled>
        </PaperStyled>
      
      </Container>
        
    </>
  );
};

export default AddMission;
