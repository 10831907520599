// store.js

import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import { loadState, saveState } from '../utils/localStorage';

// Load the persisted user state from localStorage
const persistedState = loadState();

export const store = configureStore({
  reducer: {
    user: userReducer,
  },
  preloadedState: {
    user: persistedState, // Set the preloaded state to the persisted user state
  },
});

// Subscribe to store updates and save the user state to localStorage
store.subscribe(() => {
  const state = store.getState();
  saveState(state.user); // Persist only the user slice of the state
});

export default store;
