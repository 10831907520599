import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { Button } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material'; 
import AddIcon from '@mui/icons-material/Add'; 
import logoImage from '../assets/images/omnidoc_logo.png';
import {  useSelector } from 'react-redux';  // Import Provider and useSelector from redux
import { useNavigate } from 'react-router-dom'; // Import useNavigate




const pages = ['Accueil', 'Mes demandes', 'Support'];
const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

function ResponsiveAppBar({ authenticated }) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const user = useSelector((state) => state.user.name); // Access the user state from the Redux store
  const navigate = useNavigate(); // Initialize the navigate function from react-router-dom

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = (page) => {
    setAnchorElNav(null);
    if (page === 'Mes demandes') {
      navigate('/client/my-requests'); // Adjust the route as needed
    }
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', color: 'black' , paddingRight:12  , paddingLeft:12 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
        <img
        src={logoImage}
        alt="logo"
        width={24} 
        height={24}
        style={{
            width: "84px", 
            height: "48px", 
            objectFit: "contain",
            margin: 0,
            marginRight:8,
            padding: 0,
            }}
        />

          
{authenticated &&(  
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } , mx:4}}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => handleCloseNavMenu(page)} // Pass the page to handle navigation
                sx={{ my: 2, color: '#455879', display: 'block' , fontSize:14  }}
              >
                {page}
              </Button>
            ))}
          </Box>

          )}


          {authenticated &&(          
          <Box   sx={{
      display: 'flex',
      justifyContent: 'space-between', 
      alignItems: 'center',          
    }}>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip >
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography variant="subtitle2" color="initial" sx={{
            marginX:2,
          }}>{user}</Typography>
            
          <Button
      variant="text"
            startIcon={<AddIcon />} // Use the Add icon

      sx={{
        backgroundColor: (theme) => theme.palette.primary.main, 
        paddingX:4,
        color: 'white', 
        fontFamily: 'Arial',
        '&:hover': {
          backgroundColor: (theme) => theme.palette.primary.dark,
        },
      }}
      onClick={() => navigate('/client/add-mission')} // Wrap navigate inside an anonymous function
      >
        Nouvelle demande
        </Button>
        
           </Box>
            )}


        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
 