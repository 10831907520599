import React from 'react';
import {
  Container,
  Button,
  Typography,
  Paper,
  styled,
  Box ,
  Divider,
} from '@mui/material';
import backgroundImage from '../../assets/images/omnidoc_bg.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FlagIcon from '@mui/icons-material/Flag';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import ResponsiveAppBar from '../../components/AppBar';




function MissionsDetails() {



const PaperStyled = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#ffffff', 
}));



  return (
    <>
    <ResponsiveAppBar authenticated={true}/>
    <Container 
            component="main"
            maxWidth={false} 
            sx={{
              minHeight: '80vh',
              width: '100vw', 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundImage: `url(${backgroundImage})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              overflow: 'hidden', 
              padding: 0,
              margin: 0, 
             }}
    >
      <PaperStyled
      elevation={3} sx={{
        width:"98%",
        display: "flex", 
        flexDirection:'column',
        alignItems: "start", 
        // justifyContent: "space-between",
        padding:2,
        margin:0,
        marginY:4
      }}
      >
        <Container maxWidth="main">
        <Typography variant="h1" sx={{
          fontSize:36,
          letterSpacing: "2px", 
          fontWeight: "bold",
        }} >
        Prévisualiser pour confirmation
        </Typography>
          
        </Container>
        <Container 
        maxWidth="main"
        sx={{
          width:"100%",
          display: "flex", 
          flexDirection:'row',
          alignItems: "start", 
          justifyContent: "space-between",
          padding:1,
          margin:0,
        // background:"grey"

        }}
        >


{/* Container 1 */}
  <Container
    maxWidth="lg"
    sx={{
      backgroundColor: "#F8FAFC",
      width: "32%",
      height: "250px", 
      // display: "flex",
      justifyContent: "start",
      border: "1px solid #E0E0E0", 
      borderRadius: "5px", 
    }}
  >
        <Typography variant="h1" sx={{
          letterSpacing: "1px", 
          marginY:2,
          fontSize:18,
          letterSpacing:"1px"
        }} >
        Détails de l'intervention ID : #14534 :
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:4 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <CalendarTodayIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Date de l'intervention</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >23/08/2024</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Type d'intervention</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >Ambulance médicalisé</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Priorité</Typography> 
  </Box>

  <Typography
  variant="h1"  
  sx={{
    margin: 0,
    padding: 1,  
    letterSpacing: "0.5px",
    backgroundColor: "rgba(255, 0, 0, 0.1)", 
    border: "1px solid red",
    borderRadius: "5px", 
    color: "red", 
  }}
>
  Urgent
</Typography>

        </Box>

    </Container>


    


  {/* Container 2 */}
  <Container
    maxWidth="lg"
    sx={{
      backgroundColor: "#F8FAFC",
      width: "32%",
      height: "250px", 
      // display: "flex",
      justifyContent: "start",
      border: "1px solid #E0E0E0", 
      borderRadius: "5px", 
    }}
  >
        <Typography variant="h1" sx={{
          letterSpacing: "1px", 
          marginY:2,
          fontSize:18,
          letterSpacing:"1px"
        }} >
        Détails du patient :
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:4 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <CalendarTodayIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Nom de l'entreprise</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >AXA Assurance</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Nom du patient</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >John Do</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >État</Typography> 
  </Box>

  <Typography
  variant="h1"  
  sx={{
    margin: 0,
    padding: 1,  
    letterSpacing: "0.5px",
    backgroundColor: "rgba(0, 0, 255, 0.1)",  
    border: "1px solid blue",
    borderRadius: "5px", 
    color: "blue",  
  }}
>
  En Cours
</Typography>

        </Box>

    </Container>

  {/* Container 3 */}
  <Container
    maxWidth="lg"
    sx={{
      backgroundColor: "#F8FAFC",
      width: "32%",
      height: "250px", 
      // display: "flex",
      justifyContent: "start",
      border: "1px solid #E0E0E0", 
      borderRadius: "5px", 
    }}
  >
        <Typography variant="h1" sx={{
          letterSpacing: "1px", 
          marginY:2,
          fontSize:18,
          letterSpacing:"1px"
        }} >
        Détails du contact :
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:4 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <CalendarTodayIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Numéro de téléphone</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >+212 535 76 18 76</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Adresse e-mail</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >johnexemple@mail.com</Typography>
        </Box>


    </Container>
          
        </Container>

        <Container 
        maxWidth="main"
        sx={{
          width:"100%",
          display: "flex", 
          flexDirection:'row',
          alignItems: "start", 
          justifyContent: "space-between",
          padding:1,
          margin:0,
        // background:"grey"

        }}
        >


        {/* Container 1 */}
          <Container
            maxWidth="lg"
            sx={{
              backgroundColor: "#F8FAFC",
              width: "64%",
              height: "250px", 
              // display: "flex",
              justifyContent: "start",
              border: "1px solid #E0E0E0", 
              borderRadius: "5px", 
            }}
          >
                <Typography variant="h1" sx={{
                  letterSpacing: "1px", 
                  marginY:2,
                  fontSize:18,
                  letterSpacing:"1px"
                }} >
                  Détails de l'adresse de l'intervention :
                </Typography>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex", 
                    flexDirection: 'row',
                    alignItems: "center", 
                    justifyContent: "space-between",
                    padding: 0,
                    margin: 0,
                    marginTop:4 ,
                  }}
                >
          <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
            <CalendarTodayIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
            <Typography variant="h1"
            sx={{
              margin: 0,
              padding:0,
              fontSize: "16px",
              letterSpacing:"0.5px",
              fontWeight:600
            }}
            >Adresse de départ</Typography> 
          </Box>

          <Typography variant="textbody1" 
            sx={{
              margin: 0,
              padding:0,
              letterSpacing:"0.5px"
            }}
            >HAY NAMAE N°360, 1er étage BEN SOUDA, Fes</Typography>
                </Box>

                <Divider sx={{ marginTop: 2 }} />

                <Box
                  sx={{
                    width: "100%",
                    display: "flex", 
                    flexDirection: 'row',
                    alignItems: "center", 
                    justifyContent: "space-between",
                    padding: 0,
                    margin: 0,
                    marginTop:2 ,
                  }}
                >
          <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
            <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
            <Typography variant="h1"
            sx={{
              margin: 0,
              padding:0,
              fontSize: "16px",
              letterSpacing:"0.5px",
              fontWeight:600
            }}
            >Adresse d'arrivée</Typography> 
          </Box>

          <Typography variant="textbody1" 
            sx={{
              margin: 0,
              padding:0,
              letterSpacing:"0.5px"
            }}
            >HAY NAMAE N°360, 1er étage BEN SOUDA, Fes</Typography>
                </Box>

                <Divider sx={{ marginTop: 2 }} />

                <Box
                  sx={{
                    width: "100%",
                    display: "flex", 
                    flexDirection: 'row',
                    alignItems: "center", 
                    justifyContent: "space-between",
                    padding: 0,
                    margin: 0,
                    marginTop:2 ,
                  }}
                >
          <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
            <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
            <Typography variant="h1"
            sx={{
              margin: 0,
              padding:0,
              fontSize: "16px",
              letterSpacing:"0.5px",
              fontWeight:600
            }}
            >Aller-retour</Typography> 
          </Box>

          <Typography
          variant="h1"  
          sx={{
            margin: 0,
            padding: 1,  
            letterSpacing: "0.5px",
            backgroundColor: "rgba(41, 186, 205, 0.1)",  // Background with 10% opacity of #29BACD
            border: "1px solid #29BACD",  // Solid border with #29BACD
            borderRadius: "5px", 
            color: "#29BACD",  // Text color using #29BACD
          }}
        >
        Oui
        </Typography>


                </Box>

            </Container>


            
          {/* Container 2 */}
          <Container
            maxWidth="lg"
            sx={{
              backgroundColor: "#F8FAFC",
              width: "32%",
              height: "250px", 
              // display: "flex",
              justifyContent: "start",
              border: "1px solid #E0E0E0", 
              borderRadius: "5px", 
            }}
          >
                <Typography variant="h1" sx={{
                  letterSpacing: "1px", 
                  marginY:2,
                  fontSize:18,
                  letterSpacing:"1px"
                }} >
                Détails :
                </Typography>

                <Typography variant="textbody1" 
            sx={{
              margin: 0,
              padding:0,
              letterSpacing:"0.5px"
            }}
            >Voluptate minima tempora enim quia aliquam amet. Minima harum eum at et sint voluptas et. Omnis facilis dignissimos ullam porro. Et quam ducimus non et facilis maiores et voluptatem. Nihil odit repudiandae officiis a et ex quaerat.</Typography>


          
          </Container>
        
        </Container>

    
      </PaperStyled>
      
    </Container>

    </>
  );
}

export default MissionsDetails;
