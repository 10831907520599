import React, { useState } from 'react';
import { Avatar, Box, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Typography, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AssignmentIcon from '@mui/icons-material/Assignment';
import GroupIcon from '@mui/icons-material/Group';
import ExitToAppIcon from '@mui/icons-material/ExitToApp'; // Log out icon
import { useDispatch } from 'react-redux';
import IConAdmin from '../../assets/images/admin_icon.png';
import Overview from './Overview';
import MissionPreview from '../client/MissionPreview';
import { logout } from '../../store/userSlice';
import { useNavigate } from 'react-router-dom';
const drawerWidth = 220;

const Dashboard = () => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const [pageNumber, setPageNumber] = useState(0);
  const navigate = useNavigate();

 const dispatch=useDispatch();
  // Log out function
  const handleLogout = () => {
    localStorage.removeItem('authToken');
    dispatch(logout())
    navigate('/')
  };

  return (
    <Box sx={{ display: 'flex', overflow: 'hidden' }}> {/* Ensure full-height layout */}
      <Drawer
        variant="persistent"
        open={drawerOpen}
        sx={{
          width: drawerOpen ? drawerWidth : 0,
          flexShrink: 0,
          '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar sx={{ backgroundColor: '#29BACD', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Avatar
            src={IConAdmin}
            alt="Logo"
            sx={{ width: 80, height: 60 }} // Adjust the size of the image
          />
        </Toolbar>

        <Box sx={{ overflow: 'auto', background: '#29BACD', p: 0, height: '100%' }}>
          <Typography variant="h3" sx={{ color: '#FFFFFF', p: 2 }}>
            MENU
          </Typography>
          <List>
            <ListItem
              button
              onClick={() => setPageNumber(0)}
              sx={{
                opacity: pageNumber === 0 ? 1 : 0.6,
                transition: 'opacity 0.3s',
              }}
            >
              <ListItemIcon>
                <DashboardIcon sx={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText primary="Tableau de bord" sx={{ color: '#fff' }} />
            </ListItem>

            <ListItem
              button
              onClick={() => setPageNumber(1)}
              sx={{
                opacity: pageNumber === 1 ? 1 : 0.6, // Full opacity for selected item
                transition: 'opacity 0.3s',
              }}
            >
              <ListItemIcon>
                <AssignmentIcon sx={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText primary="Les demandes" sx={{ color: '#fff' }} />
            </ListItem>

            <ListItem
              button
              onClick={() => setPageNumber(2)}
              sx={{
                opacity: pageNumber === 2 ? 1 : 0.6, // Full opacity for selected item
                transition: 'opacity 0.3s',
              }}
            >
              <ListItemIcon>
                <GroupIcon sx={{ color: '#fff' }} />
              </ListItemIcon>
              <ListItemText primary="Les équipes" sx={{ color: '#fff' }} />
            </ListItem>
          </List>

          {/* Log Out Button - Positioned at the bottom */}
          <Box sx={{ position: 'absolute', bottom: 0, width: '100%', p: 2 }}>
            <Button
              startIcon={<ExitToAppIcon />}
              onClick={handleLogout}
              sx={{
                color: '#fff',
                justifyContent: 'flex-start',
                width: '100%',
                textTransform: 'none',
              }}
            >
              Se déconnecter
            </Button>
          </Box>
        </Box>
      </Drawer>

      {/* Main content */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          overflowY: 'auto', // Ensure only vertical scrolling
          overflowX: 'hidden', // Prevent horizontal scrolling
          padding: 2,
          maxWidth: `calc(100% - ${drawerOpen ? drawerWidth : 0}px)`, // Adjust maxWidth based on drawer status
          transition: 'max-width 0.3s', // Smooth transition for drawer opening/closing
        }}
      >
        <Toolbar disableGutters>
          <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
            <MenuIcon />
          </IconButton>
        </Toolbar>

        {/* Conditional Rendering of components based on pageNumber */}
        {pageNumber === 1 && <Overview />}
        {pageNumber === 0 }
        {pageNumber === 2 && <Typography variant="h4">Les équipes</Typography>}
      </Box>
    </Box>
  );
};

export default Dashboard;
