import React ,{useState}from 'react';
import {
  Container,
  Button,
  Typography,
  Paper,
  styled,
  Box ,
  Divider,
} from '@mui/material';
import backgroundImage from '../../assets/images/omnidoc_bg.png';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import FlagIcon from '@mui/icons-material/Flag';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import EditIcon from '@mui/icons-material/Edit';
import SendIcon from '@mui/icons-material/Send';
import ResponsiveAppBar from '../../components/AppBar';
import { createIntervention } from '../../api/interventionApi';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';


function MissionPreview() {
  const navigate= useNavigate();
  const location = useLocation();
  const interventionData = location.state;
  // console.log(interventionData,'rrrrrrrrr')
  const today=new Date().toLocaleDateString() 
const PaperStyled = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(8),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#ffffff', 
}));

const [notification, setNotification] = useState(''); // State to handle notification message
const handlBack=(e)=>{
e.preventDefault();
navigate('/client/add-mission', { state: interventionData }); // Go back to the previous page

}
const handleSubmit = (e) => {
  e.preventDefault();
  
  // Simulate API call and handle success/failure
  createIntervention(interventionData)
    .then(() => {
      // On success
      setNotification({
        message: 'Demande intervention envoyée avec succès',
        type: 'success',
      });
    })
    .catch(() => {
      // On failure
      setNotification({
        message: 'Échec de l\'envoi de la demande d\'intervention',
        type: 'error',
      });
    });

  // Clear the notification after 3 seconds (optional)
  setTimeout(() => {
    setNotification({ message: '', type: '' });
  }, 2000);
};
  return (
    <>
    <ResponsiveAppBar authenticated={true}/>
    {notification.message && (
        <div
          className={`notification ${notification.type === 'success' ? 'success' : 'error'}`}
          style={{
            backgroundColor: notification.type === 'success' ? 'green' : 'red',
            color: 'white',
            padding: '10px',
            position: 'fixed',
            top: 0,
            width: '100%',
            textAlign: 'center',
            zIndex: 1000,
          }}
        >
          {notification.message}
        </div>
      )}
    <Container 
            component="main"
            maxWidth={false} 
            sx={{
              minHeight: '80vh',
              width: '100vw', 
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundImage: `url(${backgroundImage})`, 
              backgroundSize: 'cover', 
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              overflow: 'hidden', 
              padding: 0,
              margin: 0, 
             }}
    >
      <PaperStyled
      elevation={3} sx={{
        width:"98%",
        display: "flex", 
        flexDirection:'column',
        alignItems: "start", 
        // justifyContent: "space-between",
        padding:2,
        margin:0,
        marginY:4
      }}
      >
        <Container maxWidth="main">
        <Typography variant="h1" sx={{
          fontSize:36,
          letterSpacing: "2px", 
          fontWeight: "bold",
        }} >
        Prévisualiser pour confirmation
        </Typography>
          
        </Container>
        <Container 
        maxWidth="main"
        sx={{
          width:"100%",
          display: "flex", 
          flexDirection:'row',
          alignItems: "start", 
          justifyContent: "space-between",
          padding:1,
          margin:0,
        // background:"grey"

        }}
        >


{/* Container 1 */}
  <Container
    maxWidth="lg"
    sx={{
      backgroundColor: "#F8FAFC",
      width: "32%",
      height: "250px", 
      // display: "flex",
      justifyContent: "start",
      border: "1px solid #E0E0E0", 
      borderRadius: "5px", 
    }}
  >
        <Typography variant="h1" sx={{
          letterSpacing: "1px", 
          marginY:2,
          fontSize:18,
          letterSpacing:"1px"
        }} >
        Détails de l'intervention ID : #14534 :
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:4 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <CalendarTodayIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Date de l'intervention</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    > {interventionData.preferredDate ? interventionData.preferredDate : today}</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Type d'intervention</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >{interventionData.InterventionType}</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Priorité</Typography> 
  </Box>

  <Typography
  variant="h1"  
  sx={{
    margin: 0,
    padding: 1,  
    letterSpacing: "0.5px",
    backgroundColor: "rgba(255, 0, 0, 0.1)", 
    border: "1px solid red",
    borderRadius: "5px", 
    color: "red", 
  }}
>
 {interventionData.Priority}
</Typography>

        </Box>

    </Container>


    


  {/* Container 2 */}
  <Container
    maxWidth="lg"
    sx={{
      backgroundColor: "#F8FAFC",
      width: "32%",
      height: "250px", 
      // display: "flex",
      justifyContent: "start",
      border: "1px solid #E0E0E0", 
      borderRadius: "5px", 
    }}
  >
        <Typography variant="h1" sx={{
          letterSpacing: "1px", 
          marginY:2,
          fontSize:18,
          letterSpacing:"1px"
        }} >
        Détails du patient :
        </Typography>
        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:4 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <CalendarTodayIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Nom de l'entreprise</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >AXA Assurance</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Nom du patient</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >{interventionData.PatientName}</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >État</Typography> 
  </Box>

  <Typography
  variant="h1"  
  sx={{
    margin: 0,
    padding: 1,  
    letterSpacing: "0.5px",
    backgroundColor: "rgba(0, 0, 255, 0.1)",  
    border: "1px solid blue",
    borderRadius: "5px", 
    color: "blue",  
  }}
>
  En Cours
</Typography>

        </Box>

    </Container>

  {/* Container 3 */}
  <Container
    maxWidth="lg"
    sx={{
      backgroundColor: "#F8FAFC",
      width: "32%",
      height: "250px", 
      // display: "flex",
      justifyContent: "start",
      border: "1px solid #E0E0E0", 
      borderRadius: "5px", 
    }}
  >
        <Typography variant="h1" sx={{
          letterSpacing: "1px", 
          marginY:2,
          fontSize:18,
          letterSpacing:"1px"
        }} >
        Détails du contact :
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:4 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <CalendarTodayIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Numéro de téléphone</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >{interventionData.PhoneNumber}</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Adresse e-mail</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >{interventionData.emailAdress}</Typography>
        </Box>


    </Container>
          
        </Container>

        <Container 
        maxWidth="main"
        sx={{
          width:"100%",
          display: "flex", 
          flexDirection:'row',
          alignItems: "start", 
          justifyContent: "space-between",
          padding:1,
          margin:0,
        // background:"grey"

        }}
        >


{/* Container 1 */}
  <Container
    maxWidth="lg"
    sx={{
      backgroundColor: "#F8FAFC",
      width: "64%",
      height: "250px", 
      // display: "flex",
      justifyContent: "start",
      border: "1px solid #E0E0E0", 
      borderRadius: "5px", 
    }}
  >
        <Typography variant="h1" sx={{
          letterSpacing: "1px", 
          marginY:2,
          fontSize:18,
          letterSpacing:"1px"
        }} >
          Détails de l'adresse de l'intervention :
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:4 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <CalendarTodayIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Adresse de départ</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >{interventionData.StartAddress1}</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Adresse d'arrivée</Typography> 
  </Box>

  <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >{interventionData.DestinationAddress1}</Typography>
        </Box>

        <Divider sx={{ marginTop: 2 }} />

        <Box
          sx={{
            width: "100%",
            display: "flex", 
            flexDirection: 'row',
            alignItems: "center", 
            justifyContent: "space-between",
            padding: 0,
            margin: 0,
            marginTop:2 ,
          }}
        >
  <Box sx={{ display: "flex", alignItems: "center" , justifyContent:'center'  }}>
    <NoteAltIcon color='secondary' sx={{ marginRight: 1 , fontSize:24 , }} />  
    <Typography variant="h1"
    sx={{
      margin: 0,
      padding:0,
      fontSize: "16px",
      letterSpacing:"0.5px",
      fontWeight:600
    }}
    >Aller-retour</Typography> 
  </Box>

  <Typography
  variant="h1"  
  sx={{
    margin: 0,
    padding: 1,  
    letterSpacing: "0.5px",
    backgroundColor: "rgba(41, 186, 205, 0.1)",  // Background with 10% opacity of #29BACD
    border: "1px solid #29BACD",  // Solid border with #29BACD
    borderRadius: "5px", 
    color: "#29BACD",  // Text color using #29BACD
  }}
>
{interventionData.IsRoundTrip ? "oui" : "non"}
</Typography>


        </Box>

    </Container>


    


  {/* Container 2 */}
  <Container
    maxWidth="lg"
    sx={{
      backgroundColor: "#F8FAFC",
      width: "32%",
      height: "250px", 
      // display: "flex",
      justifyContent: "start",
      border: "1px solid #E0E0E0", 
      borderRadius: "5px", 
    }}
  >
        <Typography variant="h1" sx={{
          letterSpacing: "1px", 
          marginY:2,
          fontSize:18,
          letterSpacing:"1px"
        }} >
        Détails :
        </Typography>

        <Typography variant="textbody1" 
    sx={{
      margin: 0,
      padding:0,
      letterSpacing:"0.5px"
    }}
    >{interventionData.Description}</Typography>


  
    </Container>
 
        </Container>

    

        <Container maxWidth="main" sx={{ display: 'flex', justifyContent: 'end', alignContent: 'end', marginY: 4 }}>
  
  <Button
    variant="outlined"
    color="primary"
    onClick={handlBack}
    startIcon={<EditIcon />}
    sx={{
      paddingX: 4,  // Horizontal padding
      paddingY: 1.2,  // Vertical padding
    }}
  >
    Modifier l'intervention
  </Button>
  
  <Button
    variant="contained"
    color="primary"
    startIcon={<SendIcon sx={{ color: '#FFFFFF' }} />}
    sx={{
      paddingY: 1,  // Vertical padding
      paddingX: 4,  // Horizontal padding
      color: '#FFFFFF', // Set text color to white
      minWidth: 'auto', 
      marginLeft:2
    }}
    onClick={handleSubmit}  >
    Soumettre la demande
  </Button>
  
</Container>


      </PaperStyled>
      
    </Container>

    </>
  );
}

export default MissionPreview;
