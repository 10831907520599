// src/routes/AdminRoutes.jsx

import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import Dashboard from '../pages/Admin/Dashboard';
// import ManageMissions from '../pages/Admin/ManageMissions';
// import Users from '../pages/Admin/Users';
// import Settings from '../pages/Admin/Settings';

import Dashboard from '../pages/admin/Dashboard';             

const AdminRoutes = () => {
  return (
    <Routes>
      {/* <Route path="/admin/dashboard" element={<Dashboard />} />
      <Route path="/admin/manage-missions" element={<ManageMissions />} />
      <Route path="/admin/users" element={<Users />} /> */}
      <Route path="/admin" element={<Dashboard />} />
    </Routes>
  );
};

export default AdminRoutes;
