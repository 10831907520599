import React, { useState ,useEffect} from 'react';
import ResponsiveAppBar from '../../components/AppBar';
import {  
  styled,
  TextField,
  InputAdornment,
  MenuItem,
  Box,
  Container,
  Button,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Select
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import backgroundImage from '../../assets/images/omnidoc_bg.png';
import AddIcon from '@mui/icons-material/Add';
import StatusDropdown from '../../components/StatusDropdown';
import { getClientInterventions } from '../../api/interventionApi';
import { useSelector } from 'react-redux';

const PaperStyled = styled(Paper)(({ theme }) => ({
  marginTop: theme.spacing(6),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  backgroundColor: '#ffffff',
}));
const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'TYPE', headerName: 'Type', width: 150 },
  { field: 'details', headerName: 'Détails', width: 150 },
  { field: 'client', headerName: 'Client', width: 150 },
  { field: 'patiente', headerName: 'Patient', width: 150 },
  { field: 'telephone', headerName: 'Téléphone', width: 150 },
  { field: 'email', headerName: 'E-mail', width: 200 },
  { field: 'etate', headerName: 'État', width: 100 },
  { field: 'dueDate', headerName: "Date d'échéance", width: 150 },
  { field: 'priority', headerName: 'Priorité', width: 100 },
];

const PriorityButton = styled(Button)(({ priority }) => ({
  color: priority === 'Urgent' ? '#ffffff' : 'inherit',
  borderColor: priority === 'Urgent' ? '#ff0000' : 'inherit',
  backgroundColor: priority === 'Urgent' ? 'rgba(255, 0, 0, 0.3)' : 'inherit',
  border: priority === 'Urgent' ? '1px solid #ff0000' : '1px solid transparent',
  borderRadius: '15px',  
  '&:hover': {
    backgroundColor: priority === 'Urgent' ? 'rgba(255, 0, 0, 0.3)' : 'inherit',
    borderColor: priority === 'Urgent' ? '#ff0000' : 'inherit',
    boxShadow: 'none', // Removes the default box shadow on hover
  },
}));


function MyRequests() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]); // Initialize rows as an empty array
  const token=useSelector((state) => state.user.token); 
  const [searchTerm, setSearchTerm] = useState('');
const [selectedDate, setSelectedDate] = useState('');
const [selectedStatus, setSelectedStatus] = useState('');
const [selectedPriority, setSelectedPriority] = useState('');
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };  useEffect(() => {
    // Fetch client interventions when the component mounts
    const fetchInterventions = async () => {
      try {
        const interventionData = await getClientInterventions(token);
        // Format the data 
        const sortedData = interventionData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        const formattedData = sortedData.map((intervention) => ({
          id: intervention.InterventionID, 
          TYPE: intervention.InterventionType, 
          details: intervention.Description, 
          client: intervention.user.name, 
          patiente: intervention.PatientName, 
          telephone: intervention.PhoneNumber, 
          email: intervention.emailAdress, 
          etate: intervention.status.StatusName, 
          dueDate: formatDate(intervention.created_at), 
          priority: intervention.Priority, 
          Departure: intervention.StartAddress1,
        }));

        setRows(formattedData); // Set the formatted data in the state
      } catch (error) {
        console.error('Error loading interventions:', error);
      } 
    };

    fetchInterventions();
  }, []);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  
  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };
  
  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };
  
  const handlePriorityChange = (e) => {
    setSelectedPriority(e.target.value);
  };
  const filteredRows = rows
  .filter((row) => {
    const matchesSearchTerm =
      (row.TYPE && row.TYPE.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.details && row.details.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.client && row.client.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.patiente && row.patiente.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.telephone && row.telephone.includes(searchTerm)) ||
      (row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesDate = selectedDate ? row.dueDate === formatDate(selectedDate) : true;
    const matchesStatus = selectedStatus ? row.etate === selectedStatus : true;
    const matchesPriority = selectedPriority ? row.priority === selectedPriority : true;

    return matchesSearchTerm && matchesDate && matchesStatus && matchesPriority;
  })
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  return (
    <>
      <ResponsiveAppBar authenticated={true}/>
      <Container  
        component="main"
        maxWidth={false}
        sx={{
          minHeight: '80vh',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
        }}
      >
        <PaperStyled
          sx={{
            width: '95%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 4,
            marginY: 6,
          }}
        >
          <Container maxWidth="xl">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent:'space-between'
              }}
            >
              {/* Search Field */}
              <TextField
               size='small'
                variant="outlined"
                placeholder="Rechercher Missions..."
                value={searchTerm}
    onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: 250 }}
              />

              {/* Date Field */}
              <TextField
              size='small'
                variant="outlined"
                type="date"
                value={selectedDate}
                onChange={handleDateChange} 
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CalendarTodayIcon />
                    </InputAdornment>
                  ),
                }}
                sx={{ width: 200 }}
              />

              {/* Select Etat */}
              <TextField
                size='small'
                select
                variant="outlined"
                label="Sélectionner le état"
                value={selectedStatus}
                onChange={handleStatusChange}
                sx={{ width: 200 }}
              >
                <MenuItem value="en attente">En attente</MenuItem>
                <MenuItem value="Complété">Complété </MenuItem>
                <MenuItem value="en cours">En Cours</MenuItem>

              </TextField>

              {/* Select Statut */}
              <TextField
                select
                size='small'
                variant="outlined"
                label="Sélectionner le statut"
                value={selectedPriority}
                onChange={handlePriorityChange} 
                sx={{ width: 200 }}
              >
               <MenuItem value="urgent">Urgent</MenuItem>
               <MenuItem value="Planifié">Planifié</MenuItem>
              </TextField>

              {/* Filter Button */}
              <Button
              size='small'
                variant="contained"
                color='secondary'
                startIcon={<FilterListIcon />}
                sx={{
                  height: '40px',
                  color: '#FFFFFF',
                  textTransform: 'none', // Keeps "Filtrer" lowercase
                  padding: '6px 16px', // Adjusted padding for consistency
                }}
              >
                Filtrer
              </Button>
             
            </Box>

            <Paper sx={{ height: 'auto', width: '100%', marginTop: 4 }}>
              <Box sx={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.field}>{column.headerName}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
  
                            <TableBody>
            {filteredRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.TYPE}</TableCell>
                <TableCell>{row.details}</TableCell>
                <TableCell>{row.client}</TableCell>
                <TableCell>{row.patiente}</TableCell>
                <TableCell>{row.telephone}</TableCell>
                <TableCell>{row.email}</TableCell>
                <TableCell>
                    <StatusDropdown 
                        status={row.etate} 
                        // onChange={(newStatus) => handleStatusChange(row.id, newStatus)} 
                        color={row.etate === 'En cours' ? '#00A74E' : '#007BB3'} 
                        readOnly={true}
                    />
                </TableCell>
                <TableCell>{row.dueDate}</TableCell>
                <TableCell>
                  <PriorityButton priority={row.priority}>
                    {row.priority}
                  </PriorityButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
                </Table>
              </Box>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Paper>
          </Container>
        </PaperStyled>
      </Container>
    </>
  );
}

export default MyRequests;
