import React, { useState, useEffect } from 'react';
import {
  Container,
  styled,
  TablePagination,
  TextField,
  InputAdornment,
  MenuItem,
  Grid,
  Paper,
  Typography,
  Avatar,
  Box,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  IconButton,
  Toolbar,
  AppBar,
  Drawer,
  Snackbar, // Import Snackbar
  Alert // Import Alert for styled snackbar
} from '@mui/material';

import CardDash from '../../components/CardDash';
import IConOne from '../../assets/images/icon1.png';
import IConTwo from '../../assets/images/icon2.png';
import IConThree from '../../assets/images/icon3.png';
import IConFour from '../../assets/images/icon4.png';
import StatusDropdown from '../../components/StatusDropdown';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { getAllInterventionsWithUsers ,updateInterventionStatus} from '../../api/interventionApi';
import { messaging } from '../../utils/firebase';
import { onMessage } from 'firebase/messaging';
import { debounce } from 'lodash';
import notificationSound from "../../assets/sound/sound.mp3";
const Overview = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [rows, setRows] = useState([]); // Initialize rows as an empty array
  const [newStatus,setnewStatus]=useState('');
  const [notification, setNotification] = useState({ open: false, message: '', severity: 'info' }); // Snackbar state
  const [searchTerm, setSearchTerm] = useState('');
const [selectedDate, setSelectedDate] = useState('');
const [selectedStatus, setSelectedStatus] = useState('');
const [selectedPriority, setSelectedPriority] = useState('');
const formatDate = (isoDate) => {
  const date = new Date(isoDate);
  return date.toLocaleDateString('fr-FR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};
const handleSearchChange = (e) => {
  setSearchTerm(e.target.value);
};

const handleDateChange = (e) => {
  setSelectedDate(e.target.value);
};

const handleStatusChange = (e) => {
  setSelectedStatus(e.target.value);
};

const handlePriorityChange = (e) => {
  setSelectedPriority(e.target.value);
};
const filteredRows = rows
  .filter((row) => {
    const matchesSearchTerm =
      (row.TYPE && row.TYPE.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.details && row.details.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.client && row.client.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.patiente && row.patiente.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (row.telephone && row.telephone.includes(searchTerm)) ||
      (row.email && row.email.toLowerCase().includes(searchTerm.toLowerCase()));

    const matchesDate = selectedDate ? row.dueDate === formatDate(selectedDate) : true;
    const matchesStatus = selectedStatus ? row.etate == selectedStatus : true;
    const matchesPriority = selectedPriority ? row.priority === selectedPriority : true;

    return matchesSearchTerm && matchesDate && matchesStatus && matchesPriority;
  }) // Change dueDate to created_at for recent interventions
  // Sort after filtering
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

 
  
  const fetchInterventions = async () => {
    try {
      const interventionData = await getAllInterventionsWithUsers();
      // Map the fetched data to the desired structure
      const sortedData = interventionData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      const formattedData = sortedData.map((intervention) => ({
        id: intervention.InterventionID, // Adjust according to your data structure
        TYPE: intervention.InterventionType, // Adjust according to your data structure
        details: intervention.Description, // Adjust according to your data structure
        client: intervention.user.name, // Adjust according to your data structure
        patiente: intervention.PatientName, // Adjust according to your data structure
        telephone: intervention.PhoneNumber, // Adjust according to your data structure
        email: intervention.emailAdress, // Adjust according to your data structure
        etate: intervention.status.StatusID, // Adjust according to your data structure
        dueDate: formatDate(intervention.created_at), // Adjust according to your data structure
        priority: intervention.Priority, // Adjust according to your data structure
        Departure: intervention.StartAddress1, // Adjust according to your data structure
        Arrivée:intervention.DestinationAddress1,
        boncomande:intervention.OrderNumber,
        Aller_Retour: intervention.IsRoundTrip === 1 ? 'Oui' : 'Non' // Corrected conditional expression
      }));

      setRows(formattedData);
    } catch (error) {
      console.error('Error fetching interventions', error);
    }
  };

  // Fetch data on component mount
  useEffect(() => {
    fetchInterventions();

    // Set up visibility change listener to fetch data when the tab becomes visible
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        fetchInterventions();
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

    // Function to play the notification sound
  const playNotificationSound = () => {
    const audio = new Audio(notificationSound);
    audio.play();
  };

  // Set up foreground notification listener
  useEffect(() => {
    const unsubscribe = onMessage(messaging, (payload) => {
      // console.log('New foreground notification received: ', payload);
      debounce(fetchInterventions, 1000); // Refresh the table
      playNotificationSound();

      // Show custom notification
      setNotification({
        open: true,
        message: `${payload.notification.title}: ${payload.notification.body}`,
        severity: 'info' // You can customize severity: 'info', 'success', 'error', 'warning'
      });
    });

    // Clean up listener on component unmount
    return () => unsubscribe();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  
 const handleStatusIntChange=(newStatus,InterventionID)=>{
  console.log(newStatus,InterventionID,'hereee')
    updateInterventionStatus(InterventionID,newStatus);
    // Update the UI by mapping through the interventionData and updating the relevant row
    const updatedInterventions = filteredRows.map((intervention) =>
      intervention.id === InterventionID
          ? { ...intervention, etate: newStatus } // Update the status for the specific intervention
          : intervention
  );
  
  setRows(updatedInterventions);
 }
  const handleCloseNotification = () => {
    setNotification({ ...notification, open: false });
  };

  const columns = [
    { field: 'id', headerName: 'ID', width: 100 },
    { field: 'TYPE', headerName: 'Type', width: 150 },
    { field: 'details', headerName: 'Détails', width: 150 },
    { field: 'client', headerName: 'Client', width: 150 },
    { field: 'patiente', headerName: 'Patient', width: 150 },
    { field: 'telephone', headerName: 'Téléphone', width: 150 },
    { field: 'email', headerName: 'E-mail', width: 200 },
    { field: 'etate', headerName: 'État', width: 100 },
    { field: 'dueDate', headerName: "Date d'échéance", width: 150 },
    { field: 'priority', headerName: 'Priorité', width: 100 },
    { field: 'Départ', headerName: 'Departure', width: 100 },
    { field: 'Arrivée', headerName: 'Arrivée', width: 100 },
    { field: 'AllerRetour', headerName: 'Aller_Retour', width: 100 },
    { field: 'Bon de commande', headerName: 'boncomande', width: 100 },

  ];

  const PriorityButton = styled(Button)(({ priority }) => ({
    color: priority === 'Urgent' ? '#ffffff' : 'inherit',
    borderColor: priority === 'Urgent' ? '#ff0000' : 'inherit',
    backgroundColor: priority === 'Urgent' ? 'rgba(255, 0, 0, 0.3)' : 'inherit',
    border: priority === 'Urgent' ? '1px solid #ff0000' : '1px solid transparent',
    borderRadius: '15px',
    '&:hover': {
      backgroundColor: priority === 'Urgent' ? 'rgba(255, 0, 0, 0.3)' : 'inherit',
      borderColor: priority === 'Urgent' ? '#ff0000' : 'inherit',
      boxShadow: 'none',
    },
  }));
  const countInterventionsByStatus = () => {
    const counts = {
      "Complété": 0,
      "En cours": 0,
      "En attente": 0,
    };
  
    // Loop through the interventions and count based on status
    rows.forEach(row => {
      if (row.etate === 2) {
        counts["Complété"] += 1; // Increment count for "Complété"
      } else if (row.etate === 3) {
        counts["En cours"] += 1; // Increment count for "En cours"
      } else if (row.etate === 1) {
        counts["En attente"] += 1; // Increment count for "En attente"
      }
    });
  
    return counts; // Return the counts
  };
  

  // Step 2: Get counts for different statuses
  const statusCounts = countInterventionsByStatus();

  return (
    <>
      {/* Notification Snackbar */}
      <Snackbar
        open={notification.open}
        autoHideDuration={5000} // Notification will disappear after 5 seconds
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseNotification} severity={notification.severity}>
          {notification.message}
        </Alert>
      </Snackbar>

      <Grid container spacing={3} >
      <CardDash title="Interventions terminées" value={statusCounts['Complété'] || 0} icon={IConTwo} />
        <CardDash title="Interventions En Cours" value={statusCounts['En cours'] || 0} icon={IConThree} />
        <CardDash title="Interventions En attente" value={statusCounts['En attente'] || 0} icon={IConFour} />
        <CardDash title="Total Interventions" value={rows.length} icon={IConOne} />
      </Grid>

      {/* Table */}
      <Paper elevation={4} sx={{ height: 'auto', width: '100%', marginTop: 4, paddingX:2, paddingY:2  }} >
        <Box mt={4}>
          <Typography variant="h1">Les Demandes D'intervention</Typography>
          <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between', // Ensures search field is on the left and others on the right
    flexWrap: 'nowrap', // Prevent wrapping to the next line
    width: '100%', // Ensure the parent container has enough width
    gap: 2, // Optional: Adds consistent spacing between components
    marginBottom: 2 // Adds space below the field set
  }}
>
  {/* Left Side - Search Field */}
  <TextField
    size="small"
    variant="outlined"
    placeholder="Rechercher demandes..."
    value={searchTerm}
    onChange={handleSearchChange} // Handle search input
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
    sx={{ minWidth: '250px', marginRight: 2 }} // Fixed width for the search field
  />

  {/* Right Side - Other Fields */}
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      flex: 1, // This ensures the right side fields align properly
      gap: 2, // Consistent spacing between components
    }}
  >
    {/* Date Field */}
    <TextField
      variant="outlined"
      type="date"
      size="small"
      value={selectedDate}
  onChange={handleDateChange} // Handle date input
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarTodayIcon />
          </InputAdornment>
        ),
      }}
      sx={{ width: '180px' }} // Fixed width for consistency
    />

    {/* Select Etat */}
    <TextField
      select
      size="small"
      variant="outlined"
      label="Etat"
      value={selectedStatus}
      onChange={handleStatusChange} // Handle status change
      sx={{ width: '180px' }} // Fixed width
    >
       <MenuItem value="2">Complété</MenuItem>
            <MenuItem value="3">En cours</MenuItem>
            <MenuItem value="1">En attente</MenuItem>

    </TextField>

    {/* Select Statut */}
    <TextField
      select
      size="small"
      variant="outlined"
      label="Priorité"
      value={selectedPriority}
      onChange={handlePriorityChange} // Handle priority change
      sx={{ width: '180px' }} // Fixed width
    >
      <MenuItem value="urgent">Urgent</MenuItem>
      <MenuItem value="Planifié">Planifié</MenuItem>
    </TextField>

    {/* Filter Button */}
    <Button
      variant="contained"
      color="secondary"
      startIcon={<FilterListIcon />}
      sx={{
        height: '40px',
        color: '#FFFFFF',
        textTransform: 'none', // Keeps "Filtrer" lowercase
        padding: '6px 16px', // Adjusted padding for consistency
      }}
    >
      Filtrer
    </Button>
  </Box>
</Box>




              <Box sx={{ overflowX: 'auto' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {columns.map((column) => (
                        <TableCell key={column.field}>{column.headerName}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
  
                            <TableBody>
            {filteredRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.TYPE}</TableCell>
                <TableCell>{row.details}</TableCell>
                <TableCell>{row.client}</TableCell>
                <TableCell>{row.patiente}</TableCell>
                <TableCell>{row.telephone}</TableCell>
                <TableCell>{row.email}</TableCell>

               
                
                <TableCell>
                    <StatusDropdown 
                        status={newStatus?newStatus:row.etate}
                        onChange={(newStatus) => handleStatusIntChange(newStatus,row.id)} 
                        color={row.etate === 'En cours' ? '#00A74E' : '#007BB3'} 
                    />
                </TableCell>
                <TableCell>{row.dueDate}</TableCell>
                <TableCell>
                  <PriorityButton priority={row.priority}>
                    {row.priority}
                  </PriorityButton>
                </TableCell>
                <TableCell>{row.Departure}</TableCell>
                <TableCell>{row.Arrivée}</TableCell>
                <TableCell>{row.Aller_Retour}</TableCell>
                <TableCell>{row.boncomande}</TableCell>
              </TableRow>
            ))}
          </TableBody>
                </Table>
              </Box>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
           
        </Box>
        </Paper>
  
    </>
  );
};

export default Overview;
