import { messaging } from "./firebase";
import { getToken } from "firebase/messaging";

export const getFcmToken = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging,{
        vapidKey: "BGLjBA1XeMtwz8Hv1fnl557cyjKG6TQxs5anUWPlHdJqVp5hlBcSFx7uoTe6vQ0jvc2FYbfouoItejphhw5Lf6o",
      });
      console.log("FCM Token:", token);
      return token

    }
    else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
   
    
  
  } catch (error) {
    console.error("Error fetching FCM token:", error);
  }
};




  