import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  Paper,
  styled,
  CssBaseline,
  Checkbox, FormControlLabel,
  Divider,
} from '@mui/material';
import backgroundImage from '../../assets/images/omnidoc_bg.png';
import ImageSignIn from '../../assets/images/signin_img_.png';
import ResponsiveAppBar from '../../components/AppBar';
import { Box } from '@mui/material';
import logoImage from '../../assets/images/omnidoc_logo.png';
import { useNavigate } from 'react-router-dom';
import { loginUser, storeFcmToken } from '../../api/authApi'; 
import { useDispatch } from 'react-redux';
import { login } from '../../store/userSlice';
import { getFcmToken } from '../../utils/fireabseconfig';
import { useSelector } from 'react-redux';

const PaperStyled = styled(Paper)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: '#ffffff',
  width: '100%', 
}));

const FormStyled = styled('form')(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(3),
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(3, 0, 2),
}));

const SignIn = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const user = useSelector((state) => state.user); 
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const loginData = { email, password };
      const response = await loginUser(loginData);
      dispatch(login({ id: response.user.id, name: response.user.name, role: response.user.role, token: response.token }));
      localStorage.setItem('authToken', response.token);

      const fcmToken = await getFcmToken();
      if (fcmToken) {
        await storeFcmToken({ user_id: response.user.id, fcm_token: fcmToken });
      }
      if (response.user.role === "CLIENT") {
        navigate('/client/add-mission');
      } else {
        navigate('/admin');
      }
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <>
      <ResponsiveAppBar authenticated={false} />
      <CssBaseline />
      <Container
        component="main"
        disableGutters 
        maxWidth={false}
        sx={{
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundImage: `url(${backgroundImage})`,
          backgroundSize: "cover",
          backgroundPosition: 'center',
          overflow: 'hidden',
        }}
      >
        <PaperStyled elevation={3} sx={{
          display: 'flex',
          width: '90%',
          flexDirection: 'row-reverse',
          padding: 0,
        }}>
          <Container sx={{ width: "50%",marginLeft:2 }}>
            <img
              src={logoImage}
              alt="logo"
              width={150}
              height={150}
              style={{ objectFit: 'contain', margin: 0, marginRight: 8 }}
            />
            <Typography variant="h1">Se Connecter</Typography>
            <Typography variant="subtitle1">L'assistance médicale accessible à tous</Typography>
            {errorMessage && (
              <Typography color="error" variant="body2">{errorMessage}</Typography>
            )}
            <FormStyled onSubmit={handleSubmit} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h2">Adresse email</Typography>
                  <TextField
                  size='small'
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    placeholder='johnexemple@mail.com'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{ style: { fontSize: 13 } }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h2">Mot de passe</Typography>
                  <TextField
                  size='small'
                    variant="outlined"
                    required
                    fullWidth
                    name="password"
                    placeholder='Entrer le mot de passe'
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    InputProps={{ style: { fontSize: 13 } }}
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={<Typography variant='h2'>Se souvenir de moi</Typography>}
                  />
                </Grid>
              </Grid>
              <SubmitButton type="submit" fullWidth variant="contained" color="primary" sx={{  color:"#FFFFFF",fontWeight: 700 }}>
                Connexion
              </SubmitButton>
            </FormStyled>
            <Grid item xs={12}>
              <Divider />
              <Box     
      my={3}
      sx={{
        minHeight: '10px',
        color:"#000000",
        display:'flex',
        justifyContent:'center',
      alignItems:'center',
      
      }}
    >
      <Typography variant="subtitle1" sx={{ fontSize: 14, textAlign: 'center' , margin:'0'}}>
        Demander un compte
      </Typography>

      <Box
    sx={{
      width: '8px',  // Adjust size as needed
      height: '8px', // Adjust size as needed
      borderRadius: '50%',
      backgroundColor: '#8590A2',
      mx: 2  ,
    }}
  />
      
      <Typography variant="h2" sx={{ ml: 2, textAlign: 'center', margin:'0' }}>
        +212 6 27 55 55 55
      </Typography>
    </Box>
            </Grid>
          </Container>
          <Box
            sx={{
              width: "50%",
              height: "90vh", 
              background: `url(${ImageSignIn}) no-repeat center center`,
              backgroundSize: "cover",
            }}
          />
        </PaperStyled>
      </Container>
    </>
  );
};

export default SignIn;
