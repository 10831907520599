// src/routes/AuthRoutes.jsx

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignIn from '../pages/Auth/SignIn';

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
    </Routes>
  );
};

export default AuthRoutes;
