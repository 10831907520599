import React from 'react';
import { MenuItem, Select, Typography } from '@mui/material';

const StatusDropdown = ({ status, onChange, color, readOnly }) => {
    const hexToRgba = (hex, opacity) => {
        let r = 0, g = 0, b = 0;

        // 3 digits
        if (hex.length === 4) {
            r = parseInt(hex[1] + hex[1], 16);
            g = parseInt(hex[2] + hex[2], 16);
            b = parseInt(hex[3] + hex[3], 16);
        }
        // 6 digits
        else if (hex.length === 7) {
            r = parseInt(hex[1] + hex[2], 16);
            g = parseInt(hex[3] + hex[4], 16);
            b = parseInt(hex[5] + hex[6], 16);
        }

        return `rgba(${r},${g},${b},${opacity})`;
    };

    const bgColor = hexToRgba(color, 0.2); // Adjust the opacity as needed
    const borderColor = color;

    // Render as a dropdown or as a simple text based on the readOnly prop
    if (readOnly) {
        return (
            <Typography
                variant="body2"
                sx={{
                    color: color,
                    backgroundColor: bgColor,
                    borderRadius: '15px',
                    padding: '8px',
                    border: `1px solid ${borderColor}`,
                    textAlign: 'center',
                    width: '100%',
                    display: 'inline-block', // Ensures it fits well within the TableCell
                }}
            >
                {status}
            </Typography>
        );
    }

    return (
        <Select
            value={status}
            onChange={(e) => onChange(e.target.value)}
            sx={{
                color: color,
                borderRadius: '15px',
                padding: 0,
                border: `1px solid ${borderColor}`,
                backgroundColor: bgColor,
                '& .MuiSelect-icon': {
                    color: borderColor,
                },
            }}
        >
            <MenuItem value="2">Complété</MenuItem>
            <MenuItem value="3">En cours</MenuItem>
            <MenuItem value="1">En attente</MenuItem>
        </Select>
    );
};

export default StatusDropdown;
