// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getMessaging ,onMessage} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyCRKLgn6EO3m-ffGIPKMKE2qpji2bU1Uy8",
    authDomain: "firestore-crud-13d02.firebaseapp.com",
    projectId: "firestore-crud-13d02",
    storageBucket: "firestore-crud-13d02.appspot.com",
    messagingSenderId: "228971020689",
    appId: "1:228971020689:web:998fc1bf7d5e28eca69a30"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
// Register the Service Worker
if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.error('Service Worker registration failed:', error);
      });
  }
  // Handle foreground messages
// onMessage(messaging, (payload) => {
//     console.log('[firebase-messaging-sw.js] Received foreground message ', payload);
    
//     // Display notification using custom UI logic
//     const notificationTitle = payload.notification.title || 'New Notification';
//     const notificationBody = payload.notification.body || 'You have a new message.';
    
//     // You can call a function to display this in your app
//     showNotification(notificationTitle, notificationBody);
// });

// // Function to show notification in your app
// function showNotification(title, body) {
//     // Replace this alert with your own notification logic
//     alert(`${title}: ${body}`);
// }
export { messaging };
