// src/routes/ClientRoutes.jsx

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AddMission from '../pages/client/AddMission';
import MyRequests from '../pages/client/MyRequests';
import MissionPreview from '../pages/client/MissionPreview';
import MissionsDetails from '../pages/client/MissionsDetails';
const ClientRoutes = () => {
  return (
    <Routes>
      <Route path="/client/add-mission" element={<AddMission />} />
      <Route path="/client/my-requests" element={<MyRequests />} />
      <Route path="/client/preview" element={<MissionPreview />} />  
      <Route path="/client/request/details" element={<MissionsDetails />}/>  
    </Routes>
  );
};

export default ClientRoutes;
